import consumer from "./consumer"

consumer.subscriptions.create("ChefOrderNotificationsChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        $(`#${data.order_id}`).remove(); // remove the existing row for this Order ID

        if (data.order_id) {

            let status = '';

            if (data.popup) {
                if (data.popup_paid) {
                    status = "<span class='label label-primary'>Preparing...</span>&nbsp;<i class='fas fa-arrow-right text-muted'></i>&nbsp;" +
                        `<a class='label label-success' data-turbolinks='false' href='/popup/ready/${data.order_id}'>Ready</a>`;
                } else {
                    status = "<span class='label label-info'>Payment Pending...</span>"
                }
            } else {
                status = "<span class='label label-warning'>New</span>&nbsp;<i class='fas fa-arrow-right text-muted'></i>&nbsp;" +
                    `<a class='label label-info' data-turbolinks='false' href='/orders/${data.order_id}/processing'>Start</a>`;
            }

            const html = `<tr id="${data.order_id}">` +
                `<td class='text-nowrap'><a href="/orders/${data.order_id}">${data.order_id}</a></td>` +
                `<td class='text-nowrap'>${status}</td>` +
                `<td class='text-nowrap'>${data.created_at}</td>` +
                `<td class='text-nowrap'>${data.total ? data.total : ''}</td>` +
                `<td class='text-nowrap'>${data.chef_earnings}</td>` +
                "<td class='text-nowrap text-center'><i class='fas fa-times-circle fa-2x text-danger'></i></td>" +
                `<td class='text-nowrap'><a class='btn btn-primary btn-sm' data-turbolinks='false' href='/orders/${data.order_id}'>View</a></td>` +
                "</tr>";

            $(html).hide().prependTo("#orders").fadeIn("slow");
        }
    }
});
