import consumer from "./consumer";

consumer.subscriptions.create("CourierDeliveryLocationChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        if (currentLocationMarker !== undefined) {
            currentLocationMarker.setMap(null);

            new google.maps.Marker({
                position: {lat: data.lat, lng: data.lng},
                icon: currentLocationMarker,
                map
            });
        }
    }
});