$(document).on("turbolinks:load", function () {
    "use strict";

    $("#sign-up-quick-links-form").submit(function (event) {
        event.preventDefault();
        window.location.href = `/users/sign_up?email=${$('#email-address').val()}`
    });

    $("#marketplace-quick-links-form").submit(function (event) {
        event.preventDefault();
        window.location.href = `/marketplace?zip=${$('#marketplace-zip').val()}`
    });
});
